import { prefixRoute } from '../../utils/utils.routing';
import { EmbeddedScene, SceneApp, SceneAppPage, SceneFlexLayout } from '@grafana/scenes';
import { ROUTES } from '../../constants';
import { Attribution } from '../../types';
import { attributionsRow } from './attributionsRow';
import { usageRow } from './usageRow';

const getScene = (records: Attribution[] | undefined, loading: boolean, hmTenantId: string) => {
  return new EmbeddedScene({
    body: new SceneFlexLayout({
      direction: 'column',
      children: [usageRow(hmTenantId, records), ...attributionsRow(records, loading)],
    }),
  });
};

export const getAppScene = (records: Attribution[] | undefined, loading: boolean, hmTenantId: string) => {
  return new SceneApp({
    pages: [
      new SceneAppPage({
        title: 'Metrics usage attribution report',
        subTitle: 'Segment and categorize metrics usage within this stack',
        url: prefixRoute(`${ROUTES.Attributions}`),
        hideFromBreadcrumbs: true,
        getScene: () => getScene(records, loading, hmTenantId),
      }),
    ],
  });
};
