import { config } from '@grafana/runtime';
import pluginJson from '../../src/plugin.json';

// Prefix the api path
export const getPluginApiProxyUrl = () => {
  let buildUrl = config.appUrl;
  if (buildUrl.at(-1) !== '/') {
    // ensures that the API pathname is appended correctly (buildUrl seems to always have it but better to be extra careful)
    buildUrl += '/';
  }
  const apiBaseUrl = new URL(`api/plugin-proxy/${pluginJson.id}/attribution`, buildUrl);

  return apiBaseUrl;
};
