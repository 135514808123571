import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { prefixRoute } from '../../utils/utils.routing';
import { ROUTES } from '../../constants';
import { AttributionsPage } from '../../pages/Attributions';

export const Routes = () => {
  return (
    <Switch>
      <Route path={prefixRoute(`${ROUTES.Attributions}`)} component={AttributionsPage} />
      <Redirect to={prefixRoute(ROUTES.Attributions)} />
    </Switch>
  );
};
