import React from 'react';

import {
  SceneDataTransformer,
  SceneFlexItem,
  SceneFlexLayout,
  SceneQueryRunner,
  SceneReactObject,
} from '@grafana/scenes';
import { LoadingPlaceholder } from '@grafana/ui';
import { MONTH_ISO } from '../../constants';
import { Attribution, DimLabel } from '../../types';
import { getDimensions } from './dimensions';
import { BillableSeriesBy, CardinalityLinkTable } from './panels';

// Query runner definition
const reportsQueryRunner = new SceneQueryRunner({
  datasource: { uid: 'grafanacloud-attribution-report' },
  queries: [{ refId: 'A', datasource: { uid: 'my-custom-ds-uid' }, expr: '<none needed>' }],
});

const dataTransformer = new SceneDataTransformer({
  $data: reportsQueryRunner,
  transformations: [
    // TODO: Remove this transform, as we now specify that
    // FieldType.time on "Month(ISO)"
    // But see if there's a bug which needs fixing
    // where the reduceRow transform converts to
    // 02023-11 instead of unix timestamp
    {
      id: 'convertFieldType',
      options: {
        conversions: [
          {
            destinationType: 'time',
            targetField: MONTH_ISO,
          },
        ],
      },
    },
    {
      id: 'calculateField',
      options: {
        alias: 'month (unix timestamp)',
        mode: 'reduceRow',
        reduce: {
          include: [MONTH_ISO],
          reducer: 'sum',
        },
      },
    },
  ],
});

// return an array of scene flex items
export function attributionsRow(records: Attribution[] | undefined, loading: boolean) {
  if (loading) {
    return [
      new SceneFlexItem({
        body: new SceneReactObject({
          reactNode: <LoadingPlaceholder text="Loading..." />,
        }),
      }),
    ];
  }

  if (!records) {
    return [
      new SceneFlexItem({
        body: new SceneReactObject({
          component: () => (
            <div>
              <p>
                Attributions have not been configured or are still processing. It can take up to one day to generate a
                report.
              </p>
              <p>
                <a
                  className="external-link"
                  href="https://grafana.com/docs/grafana-cloud/cost-management-and-billing/generate-usage-attribution-report/"
                >
                  Learn how to generate usage attribution reports and reach out to support.
                </a>
              </p>
            </div>
          ),
        }),
      }),
    ];
  }

  if (records && records.length === 0) {
    return [
      new SceneFlexItem({
        body: new SceneReactObject({
          component: () => (
            <div>
              <p>There are no attributions.</p>
              <p>
                <a
                  className="external-link"
                  href="https://grafana.com/docs/grafana-cloud/cost-management-and-billing/generate-usage-attribution-report/"
                >
                  Learn how to generate usage attribution reports.
                </a>
              </p>
            </div>
          ),
        }),
      }),
    ];
  }

  const dim = getDimensions(records).map((dimension: DimLabel) => [
    new SceneFlexItem({
      $data: dataTransformer,
      minWidth: '60%',
      md: {
        width: '100%',
      },
      minHeight: 400,
      body: BillableSeriesBy(dimension),
    }),
    new SceneFlexItem({
      $data: dataTransformer,
      minWidth: '39%',
      md: {
        width: '100%',
      },
      minHeight: 400,
      body: CardinalityLinkTable(dimension),
    }),
  ]);

  return [
    new SceneFlexLayout({
      direction: 'row',
      wrap: 'wrap',
      children: [...dim.flat()],
    }),
  ];
}
