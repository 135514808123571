import pluginJson from './plugin.json';

export const PLUGIN_BASE_URL = `/a/${pluginJson.id}`;

export enum ROUTES {
  Attributions = 'attributions',
}

export const BILLABLE_SERIES = 'BillableSeries';
export const DOLLAR_COST = 'dollarCost';
export const MONTH_ISO = 'Month(ISO)';
