import { SceneDataTransformer, VizPanel } from '@grafana/scenes';
import { DimLabel, isDimLabel, Attribution } from 'types';
import { formatTitleDate } from 'utils/utils.formating';

export function BillableSeriesTotalThisMonth(records: Attribution[] | undefined) {
  const formattedDate = records ? formatTitleDate(records) : 'this month';

  return new VizPanel({
    fieldConfig: {
      defaults: {
        mappings: [],
        color: {
          fixedColor: 'text',
          mode: 'fixed',
        },
        unit: 'short',
      },
      overrides: [],
    },
    options: {
      reduceOptions: {
        values: false,
        calcs: ['lastNotNull'],
        fields: '',
      },
      orientation: 'vertical',
      textMode: 'auto',
      wideLayout: true,
      colorMode: 'value',
      graphMode: 'none',
      justifyMode: 'auto',
      showPercentChange: true,
      percentChangeColorMode: 'inverted',
      text: {},
    },
    title: `Total usage in ${formattedDate}`,
    description: 'Percentage change is relative to the previous month',
    pluginId: 'stat',
  });
}
export function SumBillableSeriesByMonth() {
  const dataTransformer = new SceneDataTransformer({
    transformations: [
      {
        id: 'calculateField',
        options: {
          alias: 'Time2',
          binary: {
            left: 'Time',
            operator: '-',
            right: '1',
          },
          mode: 'binary',
          reduce: {
            include: ['Time'],
            reducer: 'sum',
          },
        },
      },
      {
        id: 'convertFieldType',
        options: {
          conversions: [
            {
              destinationType: 'time',
              targetField: 'Time2',
            },
          ],
          fields: {},
        },
      },
      {
        id: 'formatTime',
        options: {
          outputFormat: 'MMM',
          timeField: 'Time2',
          timezone: 'utc',
          useTimezone: true,
        },
      },
    ],
  });

  return new VizPanel({
    $data: dataTransformer,
    fieldConfig: {
      defaults: {
        custom: {
          drawStyle: 'bars',
          lineInterpolation: 'linear',
          barAlignment: 1,
          lineWidth: 3,
          fillOpacity: 15,
          gradientMode: 'none',
          spanNulls: false,
          insertNulls: false,
          showPoints: 'auto',
          pointSize: 5,
          stacking: {
            mode: 'none',
            group: 'A',
          },
          axisPlacement: 'auto',
          axisLabel: '',
          axisColorMode: 'text',
          axisBorderShow: false,
          scaleDistribution: {
            type: 'linear',
          },
          axisCenteredZero: false,
          hideFrom: {
            tooltip: false,
            viz: false,
            legend: false,
          },
          thresholdsStyle: {
            mode: 'off',
          },
          axisSoftMin: 0,
        },
        color: {
          fixedColor: 'text',
          mode: 'fixed',
        },
        mappings: [],
        unit: 'short',
      },
      overrides: [],
    },
    options: {
      barWidth: 0.7,
      tooltip: {
        mode: 'single',
        sort: 'none',
        maxHeight: 600,
      },
      legend: {
        showLegend: false,
        displayMode: 'list',
        placement: 'bottom',
        calcs: [],
      },
    },
    title: 'Billable series by month, total, this stack',
    pluginId: 'barchart',
  });
}

export function BillableSeriesBy(dimension: DimLabel) {
  const formattedDimension = isDimLabel(dimension) ? dimension.replace('label_', '').concat(' label') : dimension;

  const dataTransformer = new SceneDataTransformer({
    transformations: [
      {
        id: 'groupBy',
        options: {
          fields: {
            BillableSeries: {
              aggregations: ['sum'],
              operation: 'aggregate',
            },
            'Month(ISO)': {
              aggregations: [],
              operation: 'groupby',
            },
            [dimension]: {
              aggregations: [],
              operation: 'groupby',
            },
          },
        },
      },
      {
        id: 'sortBy',
        options: {
          fields: {},
          sort: [
            {
              desc: true,
              field: 'BillableSeries (sum)',
            },
          ],
        },
      },
      {
        id: 'groupingToMatrix',
        options: {
          columnField: `${dimension}`,
          rowField: 'Month(ISO)',
          valueField: 'BillableSeries (sum)',
        },
      },
      {
        id: 'convertFieldType',
        options: {
          conversions: [
            {
              destinationType: 'time',
              targetField: `Month(ISO)\\${dimension}`,
            },
          ],
          fields: {},
        },
      },
      {
        id: 'sortBy',
        options: {
          fields: {},
          sort: [
            {
              field: `Month(ISO)\\${dimension}`,
            },
          ],
        },
      },
      {
        id: 'formatTime',
        options: {
          outputFormat: 'MMM',
          timeField: `Month(ISO)\\${dimension}`,
          // Must not format the time in any timezone, but instead must use whatever default
          // is in place, because the "convertFieldType" step above will have already converted
          // using that same default timezone.
          // ~~ timezone: 'utc', ~~
          // ~~ useTimezone: true, ~~
        },
      },
      {
        id: 'organize',
        options: {
          renameByName: {
            Value: 'unaccounted',
          },
        },
      },
    ],
  });

  return new VizPanel({
    $data: dataTransformer,
    fieldConfig: {
      defaults: {
        custom: {
          lineWidth: 1,
          fillOpacity: 80,
          gradientMode: 'none',
          axisPlacement: 'auto',
          axisLabel: '',
          axisColorMode: 'text',
          axisBorderShow: false,
          scaleDistribution: {
            type: 'linear',
          },
          axisCenteredZero: false,
          hideFrom: {
            tooltip: false,
            viz: false,
            legend: false,
          },
          thresholdsStyle: {
            mode: 'off',
          },
        },
        color: {
          mode: 'palette-classic',
        },
        mappings: [],
        noValue: '0',
        unit: 'short',
      },
      overrides: [],
    },
    options: {
      barRadius: 0,
      barWidth: 0.7,
      fullHighlight: false,
      groupWidth: 0.7,
      tooltip: {
        mode: 'single',
        sort: 'none',
      },
      legend: {
        showLegend: true,
        displayMode: 'table',
        placement: 'right',
        calcs: ['last'],
        sortBy: 'Last',
        sortDesc: true,
      },
      orientation: 'auto',
      showValue: 'never',
      stacking: 'normal',
      xTickLabelRotation: 0,
      xTickLabelSpacing: 0,
    },
    title: `Billable series by ${formattedDimension ? formattedDimension : dimension} by month`,
    description: 'The sum of attributions may not exactly match your total metrics consumption',
    pluginId: 'barchart',
  });
}

export function CardinalityLinkTable(dimension: DimLabel) {
  const formattedDimension = isDimLabel(dimension) ? dimension.replace('label_', '') : dimension;
  const dataTransformer = new SceneDataTransformer({
    transformations: [
      {
        id: 'groupBy',
        options: {
          fields: {
            BillableSeries: {
              aggregations: ['sum'],
              operation: 'aggregate',
            },
            'Month(ISO)': {
              aggregations: [],
              operation: 'groupby',
            },
            [dimension]: {
              aggregations: [],
              operation: 'groupby',
            },
          },
        },
      },
      {
        id: 'sortBy',
        options: {
          sort: [
            {
              desc: true,
              field: 'Month(ISO)',
            },
          ],
        },
      },
      {
        id: 'groupBy',
        options: {
          fields: {
            'BillableSeries (sum)': {
              aggregations: ['first'],
              operation: 'aggregate',
            },
            [dimension]: {
              aggregations: [],
              operation: 'groupby',
            },
          },
        },
      },
      {
        id: 'organize',
        options: {
          excludeByName: {},
          includeByName: {},
          indexByName: {},
          renameByName: {
            'BillableSeries (sum) (first)': 'Billable Series',
            [dimension]: formattedDimension,
          },
        },
      },
      {
        id: 'sortBy',
        options: {
          fields: {},
          sort: [
            {
              field: 'Billable Series',
              desc: true,
            },
          ],
        },
      },
    ],
  });

  return new VizPanel({
    $data: dataTransformer,
    fieldConfig: {
      defaults: {
        custom: {
          align: 'auto',
          cellOptions: {
            type: 'auto',
          },
          inspect: false,
        },
        mappings: [],
        color: {
          mode: 'thresholds',
        },
        links: [
          {
            title: `Cardinality for ${formattedDimension} \${__data.fields.${formattedDimension}}`,
            url: `/d/cardinality-management?var-filter=${formattedDimension}%7C%3D%7C\${__data.fields.${formattedDimension}}&scenes=false`,
          },
        ],
        unit: 'short',
      },
      overrides: [
        {
          matcher: {
            id: 'byType',
            options: 'string',
          },
          properties: [
            {
              id: 'mappings',
              value: [
                {
                  type: 'special',
                  options: {
                    match: 'empty',
                    result: {
                      text: 'unaccounted',
                      index: 0,
                    },
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    options: {
      showHeader: true,
      cellHeight: 'sm',
    },
    title: `Current series count per ${formattedDimension} label value`,
    description: 'Links to cardinality dashboards',
    pluginId: 'table',
  });
}
