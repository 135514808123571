import { Attribution } from '../types';
import { parse, getYear, format } from 'date-fns';

export const formatTitleDate = (records: Attribution[]) => {
  const lastMonth = findLastMonth(records)?.['Month(ISO)'] ?? null;

  const date = lastMonth ? parse(lastMonth, 'yyyy-MM', new Date()) : null;
  if (!date) {
    return 'this month';
  }
  const year = getYear(date);
  const monthName = format(date, 'MMMM');

  return `${monthName} ${year}`;
};

// Find last month in 3 months of data 'Month(ISO)' no matter what order is returned from the api
export const findLastMonth = (arr: Attribution[]) => {
  if (arr.length === 0) {
    return null;
  }

  return arr.reduce((earliest, current) => {
    const latestDate = new Date(earliest['Month(ISO)']);
    const currentDate = new Date(current['Month(ISO)']);

    return currentDate > latestDate ? current : earliest;
  });
};
