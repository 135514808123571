import { parse } from 'csv-parse/sync';
import _ from 'lodash';
import { Attribution } from 'types';
import { getPluginApiProxyUrl } from '../utils/utils.api';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

export const useGetRecords = (): UseQueryResult<Attribution[], Error> => {
  return useQuery({
    queryKey: ['todos'],
    queryFn: getRecords,
    retry: false,
  });
};

async function getRecords() {
  const response = await fetch(getPluginApiProxyUrl(), {
    headers: {
      Accept: 'text/csv',
    },
  });

  if (!response.ok) {
    // Throw an error with the response status and status text
    throw new Error(`${response.status} ${response.statusText}`);
  }

  const csv = await response.text();
  const records: Attribution[] = parse(csv, {
    columns: true,
    skipEmptyLines: true,
    cast: true,
  });

  return records;
}
